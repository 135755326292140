@font-face {
  font-family: "Garamond";
  src: url("./static/fonts/EBGaramond.ttf") format("truetype");
}

body {
	font-family: Garamond !important;
}

h1 {
	font-size: 30px;
	text-align: center;
}

h2 {
	text-align: center;
}

p, ol {
	font-size: 17px;
}

.logo {
	height: 48px;
	padding-right: 8px;
}

ul {
	width: 200px !important;
    margin: auto;
    text-align: left;
}

.fullBoard {
	max-width: 600px !important;
	margin: auto !important;
}


@keyframes myAnimation{
  0%{
    opacity: 1;
    transform: rotateX(90deg);
  }
  100%{
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.gameBoard {
    animation-name: myAnimation;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
}

.smallSquare {
	transition: top 4.5s ease;
}

.instruction {
	font-size: 17px;
	margin-bottom: 3px;
	height: 78px;
	margin-top: 10px;
	place-content: space-around;
}
.instructionText {
	max-width: 300px;
	margin: auto;
}

.square, .square7,
.smallSquare {
  background: #fff;
  border: 3px solid #eee !important;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin-right: 0px;
  margin-top: 0px;
  padding: 0 !important;
  text-align: center;
}

.wrongSquare {
	filter: opacity(0.2);
}

.smallSquare {
	border: 0.5px solid #eee !important;
}

.currentSquare {
	border: 3px solid #e70000 !important;
}

@media (max-width: 300px) {
	.square {
		width: calc(87vw / 5) !important;
        height: calc(87vw / 5) !important;
    }
	.square7 {
		width: calc(87vw / 7) !important;
        height: calc(87vw / 7) !important;
    }
	.smallSquare {
		width: calc(87vw / 15) !important;
        height: calc(87vw / 15) !important;
    }
	.col1 {
		width: 33% !important;
		font-size: 14px;
		float: left;
	}
	.logo {
        height: 29px;
        padding-right: 8px;
    }
    .navbar-brand {
        font-size: 22px !important;
    }
    .instruction {
        font-size: 12px;
    }
}

@media (max-width: 575px) {
	.square {
		width: calc(92vw / 5);
        height: calc(92vw / 5);
    }
	.square7 {
		width: calc(92vw / 7);
        height: calc(92vw / 7);
    }
	.smallSquare {
		width: calc(92vw / 15);
        height: calc(92vw / 15);
    }
	.col1 {
		width: 33% !important;
		font-size: 16px;
		float: left;
	}
    .instruction {
        font-size: 15px !important;
    }
}

@media (min-width: 575px) {
	.square {
		width: 72px !important;
        height: 72px !important;
    }
	.square7 {
		width: 52px !important;
        height: 52px !important;
    }
	.smallSquare {
		width: 24px !important;
        height: 24px !important;
    }
}
@media (min-width: 875px) {
	.square {
		width: 85px !important;
        height: 85px !important;
    }
	.square7 {
		width: 63px !important;
        height: 63px !important;
    }
	.smallSquare {
		width: 29px !important;
        height: 29px !important;
    }
}


@media (min-width: 575px) {

	.col1 {
		width: 100% !important;
		font-size: 18px;
		float: left;
	}
	.gameStats {
		margin-top: 80px !important;
	}
}


.col2 {
	width: 83% !important;
	float: left;
}
.col3 {
	width: 17% !important;
	float: left;
}

@media (min-width: 768px) {
	.container {
        max-width: 700px !important;
	}
}
@media (min-width: 992px) {
	.container {
        max-width: 800px !important;
	}
}
@media (min-width: 1200px) {
	.container {
        max-width: 800px !important;
	}
}


td {
	position: relative;
}

.image {
	position: absolute;
    top: 0;
    left: 0;
    opacity: 100%;

}

.square img,
.square7 img,
.smallSquare img {
	width: 100%;
	transition: transform 3s ease-in-out; /* Add a transition for the transform property */
	float: left;
}

.bigImg {
	width: 90%;
	margin-left: 5%;
}


.row {
	text-align: center;
}
.navbar {
	background-color: white !important;
}

.footer {
	background-color: white !important;
	padding-top: 1px;
}

.mainPart {
	padding-top: 20px;
	padding-bottom: 30px;
	background-color: #ececd9 !important;
}

.topPart {
	padding-bottom: 0px;
}

.whitePart {
	padding-top: 20px;
}

.moreInfo {
	padding-top: 20px;
	padding-bottom: 10px;
	text-align: justify;
	font-size: 15px;
}

.moreInfo .row {
	text-align: justify;
	margin-bottom: 20px;
}
.moreInfo img {
	width: 140px;
    border: 3px solid #00104e;
    margin-top: 15px;
}

.instructionInfo,
.instructionInfo ol {
	text-align: justify !important;
}
.instructionInfo span {
	text-align: center !important;
}

.instructionInfo img {
	width: 60%;
	margin-left: 20%;
	margin-top: 20px;
	margin-bottom: 20px;
}


.moreIconTiles img {
	width: 33%;
	padding-bottom: 20px;
}

@media (max-width: 575px) {
	.moreInfo img {
		display: none;
	}
}

@media (min-width: 575px) {
	.moreIconTiles img {
		display: none;
	}
	.moreInfo {
    	padding-top: 40px;
    }
}

.intro {
	max-width: 400px !important;
	font-size: 18px;
	margin: auto;
	text-align: center;
	margin-bottom: 20px;
}

.paragraph {
	text-align: justify;
}

.finish {
	font-size: 18px;
}

.default-btn {
	margin-top: 20px;
	margin-bottom: 20px;
    background-color: #011050 !important;
    border-color: #011050 !important;
    padding: 6px 50px !important;
    font-size: 23px !important;
    color: white !important;
}

.small-btn {
	background-color: #011050 !important;
    border-color: #011050 !important;
    color: white !important;
    margin-top: 5px;
	max-width: 200px !important;
}


a {
	color: #011050 !important;
}

.resultsTable {
	max-width: 300px !important;
	margin: auto;
	margin-bottom: 20px;
	text-align: center;
}

.resultsTable thead {
	border-bottom: 2px solid #011050;
}

.animate__animated.animate__backOutLeft {
  --animate-duration: 0.5s;
}

.bolder {
	font-weight: 700;
}

.topDesktopAd {
	display: inline-block !important;
	width: 728px;
	height: 90px;
	margin: auto;
}

.adsbygoogle {
	margin-top: 0px;
	margin-bottom: 0px;
}

.notification-container {
	position: absolute !important;
	top: auto !important;
	left: auto !important;
	width: 400px !important;
	max-width: 100% !important;
	min-width: 60% !important;
}

.guessRow {
	width: 200px;
	margin: auto;
	font-size: 17px;
}

.guessInput {
	max-width: 200px !important;
	margin: 0px 10px;
	margin-top: 5px;
}

.guessRowButton4,
.guessRowButton5 {
	width: 300px !important;
	margin: auto !important;
}

.guessRowButton4 .rowLetter {
	width: calc(25% - 8px) !important;
}
.guessRowButton5 .rowLetter {
	width: calc(20% - 8px) !important;
}

.rowLetter {
	height: 50px;
	font-size: 26px !important;
	line-height: 50px;
	background-color: white;
	text-align: center;
	margin: 3px 3px;
}

input.rowLetter {
	background-color: #dddcce !important;
	border-color: #b9b6a0;
}

.enter-btn {
	margin: 10px 2px !important;
}

.help-btn {
	background-color: #437c24 !important;
	border-color: #437c24 !important;
}


.goodLetter {
	background-color: #76c14d;
}

.guessError {
	background-color: #e74402;
	height: 45px;
	line-height: 45px;
	font-size: 17px;
    width: 295px;
    margin: auto;
    color: white;
    border-radius: 21px;
    margin-top: 15px;
}

.similarWords {
	max-width: 400px;
	margin: auto;
	contain: content;
}

.similarWord {
	width: 70px;
	background-color: white;
	margin: 3px 5px;
	float: left;
	border-radius: 5px;
}